import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const StyledCard = styled.div`
  display: grid;
  grid-template-rows: 100px min-content min-content min-content min-content;
  background-color: var(--surface);
  border: 1px solid var(--surface-border);
  justify-content: stretch;
  justify-items: center;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
  gap: 1rem;
  transition: var(--standard-transition);
`;

export const Card = ({ children }: Props) => {
  return <StyledCard>{children}</StyledCard>;
};
