import React from 'react';
import { graphql } from 'gatsby';
import { Heroes } from '../components/Heroes';
import { Hero } from '../generated/graphql';
import { Container } from '../components/styled/Container';
import { SEO } from '../components/SEO';

interface Props {
  data: {
    allSanityHero: {
      edges: {
        node: Hero;
      }[];
    };
  };
}

const heroes = ({ data }: Props) => {
  const heroes = data.allSanityHero.edges;

  return (
    <Container width='wide' pad>
      <SEO title={'Heroes'} />
      <Heroes heroes={heroes} />
    </Container>
  );
};

export default heroes;

export const HeroesQuery = graphql`
  query MyQuery {
    allSanityHero(sort: { fields: name }, limit: 200) {
      edges {
        node {
          name
          element
          gender
          rarity
          class
          hero_type
          slug {
            current
          }
          image {
            asset {
              id
            }
          }
        }
      }
    }
  }
`;
