import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IFilterType,
  useElementState,
  IBoolean,
  IFilterObject,
} from '../lib/state';
import { HeroCard } from './HeroCard';
import { GridWrapper } from './styled/GridContainer';
import { HeroFilters } from './HeroFilters';
import { useHeroes } from '../lib/heroContext';
import { Link } from 'gatsby';
import { Hero } from '../generated/graphql';

interface Props {
  heroes: {
    node: Hero;
  }[];
}

const HeroesWrapper = styled.div`
  width: 100%;
  max-width: var(--wide-width);
  display: grid;
  gap: 1rem;
`;

export const Heroes = ({ heroes }: Props) => {
  const heroContext = useHeroes();
  const { filters, setFilter } = useElementState();
  const [filteredHeroes, setFilteredHeroes] = useState([]);

  const filter = async () => {
    const filtered = heroes.filter((entry) => {
      let returnVal = true;
      const hero = entry.node;
      for (let index = 0; index < filters.length; index++) {
        const filter = filters[index];
        const property = hero[filter.filterType];
        if (!filter[property]) {
          returnVal = false;
          break;
        }
      }
      return returnVal;
    });
    setFilteredHeroes(() => filtered);
  };

  const handleChange = (input: {
    filterType: IFilterType;
    e: React.FormEvent<HTMLInputElement>;
  }) => {
    const label = input.e.currentTarget.name;
    const newVal: IBoolean = {
      [label]: input.e.currentTarget.checked,
    };
    setFilter(input.filterType, [newVal]);
  };

  const setAll = (e: any, filter: IFilterObject) => {
    const newObjects: IBoolean[] = [];
    for (const [key, value] of Object.entries(filter)) {
      if (key !== 'filterType') {
        newObjects.push({ [key]: true });
      }
    }
    setFilter(filter.filterType, newObjects);
  };

  const removeAll = (e: any, filter: IFilterObject) => {
    const newObjects: IBoolean[] = [];
    for (const [key, value] of Object.entries(filter)) {
      if (key !== 'filterType') {
        newObjects.push({ [key]: false });
      }
    }
    setFilter(filter.filterType, newObjects);
  };

  useEffect(() => {
    filter();
    heroContext.allHeroes.setHeroes(() => heroes);
  }, [heroes, filters]);

  useEffect(() => {
    heroContext.filteredHeroes.setHeroes(() => filteredHeroes);
  }, [filteredHeroes]);

  return (
    <HeroesWrapper>
      <h1>Heroes</h1>
      <HeroFilters
        filters={filters}
        changeHandler={handleChange}
        setAll={setAll}
        removeAll={removeAll}
      />
      <div>
        {filteredHeroes.length > 0 && (
          <div>
            Showing {filteredHeroes.length} of {heroes.length} heroes:{' '}
            <Link to={filteredHeroes[0].node.slug.current}>
              Browse Filtered Heroes
            </Link>
          </div>
        )}
        {filteredHeroes.length === 0 && (
          <div>
            Showing {filteredHeroes.length} of {heroes.length} heroes: Please
            select some heroes
          </div>
        )}
      </div>
      <GridWrapper columnWidth={200} gap={1}>
        {filteredHeroes.map((hero) => (
          <HeroCard key={hero.node.name} hero={hero.node} />
        ))}
      </GridWrapper>
    </HeroesWrapper>
  );
};
