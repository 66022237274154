import React, { useState } from 'react';
import styled from 'styled-components';
import { IFilterObject } from '../lib/state';
import { GridWrapper } from './styled/GridContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  toggleValues?: { [name: string]: boolean };
  changeHandler?: any;
  setAll?: any;
  removeAll?: any;
  filterTitle?: string;
  filter: IFilterObject;
}

const ComponentWrapper = styled.div`
  background-color: var(--surface-alt);
  border: 1px solid var(--surface-border-alt);
  padding: 0.5rem 1rem;
  transition: var(--standard-transition);
`;

const Wraps = styled.div`
  display: grid;
`;

const StyledHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;

const StyledButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  span:first-child {
    margin-right: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
`;

const StyledLabel = styled.label`
  text-align: center;
  text-transform: capitalize;
  padding: 0.05rem 0.15rem;
  cursor: pointer;
  transition: var(--standard-transition);
  &:hover {
    filter: brightness(95%);
  }
`;

const Filters = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1.25rem 0 0.5rem;
`;

interface IHiddenCheckbox {
  backgroundColor: string;
}

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  & + label {
    border: 1px solid;
    border-radius: 1rem;
    background-color: var(--control-bg-color);
    color: var(--control-text-color);
    border-color: var(--control-border-color);
  }
  &:checked + label {
    border: 1px solid;
    background-color: var(--control-bg-color-active);
    color: var(--control-text-color-active);
    border-color: var(--control-border-color-active);
  }
  &:focus + label {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

export const ToggleGroup = ({
  changeHandler,
  setAll,
  removeAll,
  filterTitle,
  filter,
}: Props) => {
  // const [isExpanded, setIsExpanded] = useState(true);
  const filterType = filter.filterType;
  const arr = Object.entries(filter);
  const p = arr.findIndex((entry) => entry[0] === 'filterType');
  arr.splice(p, 1);

  const prettify = (input: string) => {
    return input
      .replace(/_/g, ' ')
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  return (
    <ComponentWrapper>
      <StyledHeaderWrapper>
        {/* <StyledButton
          aria-expanded={isExpanded}
          aria-controls={`${filter.filterType}Filter`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>
            <FontAwesomeIcon icon={isExpanded ? faTimes : faPlus} />
          </span>
          <span>{prettify(filterTitle)}</span>
        </StyledButton> */}
        <div>{prettify(filterTitle)}</div>
        <ButtonWrapper>
          <StyledButton onClick={(e) => setAll(e, filter)}>All</StyledButton>
          <StyledButton onClick={(e) => removeAll(e, filter)}>
            None
          </StyledButton>
        </ButtonWrapper>
      </StyledHeaderWrapper>

      <Filters id={`${filter.filterType}Filter`}>
        <GridWrapper columnWidth={100} gap={1} className='my-2'>
          {arr.map((entry) => (
            <Wraps className='grid' key={`${entry[0]}Input`}>
              <HiddenCheckbox
                id={entry[0]}
                name={entry[0]}
                checked={entry[1] as boolean}
                onChange={(e) => changeHandler({ filterType, e })}
              />
              <StyledLabel htmlFor={entry[0]} key={`${entry[0]}Input`}>
                {entry[0]}
              </StyledLabel>
            </Wraps>
          ))}
        </GridWrapper>
      </Filters>
    </ComponentWrapper>
  );
};
