import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { IFilterObject } from '../lib/state';
import { Box } from './styled/Box';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { ToggleGroup } from './ToggleGroup';

interface Props {
  filters: IFilterObject[];
  changeHandler: any;
  setAll: any;
  removeAll: any;
}

const Label = styled.button`
  background: transparent;
  border: none;
  span:first-child {
    margin-right: 0.5rem;
  }
`;

const FilterContent = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
`;

export const HeroFilters = ({
  filters,
  setAll,
  removeAll,
  changeHandler,
}: Props) => {
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  return (
    <Box>
      <Label
        role='button'
        aria-expanded={isFiltersExpanded}
        aria-controls={`AllFilters`}
        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
      >
        <span>
          <FontAwesomeIcon icon={isFiltersExpanded ? faTimes : faPlus} />
        </span>
        <span>Hero Filters</span>
      </Label>
      {isFiltersExpanded && (
        <FilterContent id={'AllFilters'}>
          {filters.map((filter) => (
            <ToggleGroup
              key={filter.filterType}
              filter={filter}
              filterTitle={filter.filterType}
              changeHandler={changeHandler}
              setAll={setAll}
              removeAll={removeAll}
            />
          ))}
        </FilterContent>
      )}
    </Box>
  );
};
