import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  gap?: number;
  columnWidth?: number;
  className?: string;
  justifyContent?: string;
  [elem: string]: any;
}

interface IGridWithColumns {
  readonly columns: string;
  readonly gap?: number;
  readonly justifyContent?: string;
}

const GridWithColumns = styled.div<IGridWithColumns>`
  display: grid;
  ${(props) => props.columns};
  ${(props) => props.justifyContent};
  gap: ${(props) => props.gap}rem;
  width: 100%;
  transition: var(--standard-transition);
`;

export const GridWrapper = ({
  children,
  gap,
  columnWidth,
  justifyContent,
  ...rest
}: Props) => {
  const gtc = columnWidth
    ? `grid-template-columns: repeat(auto-fill, minmax(${columnWidth}px, 1fr));`
    : '';

  const just = justifyContent ? `justify-content: ${justifyContent}` : '';

  return (
    <GridWithColumns
      columns={gtc}
      justifyContent={just}
      gap={gap || 0}
      {...rest}
    >
      {children}
    </GridWithColumns>
  );
};
