import { Link } from 'gatsby';
import Img, { GatsbyImageProps } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { Hero } from '../generated/graphql';
import { HeroImage } from './HeroImage';
import { Card } from './styled/Card';
import { Tag } from './styled/Tag';

interface Props {
  hero: Hero;
}

const StyledName = styled(Link)`
  text-decoration: none;
  color: var(--text-color);
  font-weight: 600;
  text-align: center;
  align-self: flex-start;
  /* transition: var(--standard-transition); */
`;

const Meta = styled.div`
  color: var(--text-secondary);
  font-weight: 500;
  text-transform: capitalize;
  /* transition: var(--standard-transition); */
`;

export const HeroCard = ({ hero }: Props) => {
  const url = `/heroes/${hero.slug.current}`;

  return (
    <div>
      <Card>
        <HeroImage heroImageId={hero.image.asset.id} alt={hero.name} />
        <StyledName to={url}>{hero.name}</StyledName>
        <Meta>{hero.class}</Meta>
        <Tag rarity={hero.rarity}>{hero.rarity}</Tag>
      </Card>
    </div>
  );
};
